<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="d-none d-sm-block"
        text
        @click="init"
      >
        Ny opgavebanksag
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        {{ $t(`taskRequests.createDialog.${!!createResult ? 'titleCreated' : 'title'}`) }}
      </v-card-title>

      <Loading :loading="!loaded" />

      <v-card-text v-if="loaded && model">
        <v-container
          v-if="!createResult"
          fluid
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="model.data.location"
                :items="model.metadata.locations"
                item-text="text"
                item-value="value"
                label="Lokation"
                return-object
                :search-input.sync="locationInput"
                auto-select-first
                small-chips
                outlined
                dense
                @input="locationInput = null"
              />
              <v-autocomplete
                v-model="model.data.departments"
                :items="model.metadata.departments"
                item-text="text"
                item-value="value"
                label="Enheder"
                return-object
                :search-input.sync="departmentInput"
                auto-select-first
                small-chips
                outlined
                dense
                multiple
                @input="departmentInput = null"
              />
              <v-autocomplete
                v-model="model.data.subjectAreas"
                :items="model.metadata.subjectAreas"
                item-text="text"
                item-value="value"
                label="Tekniske områder"
                return-object
                :search-input.sync="subjectInput"
                auto-select-first
                small-chips
                outlined
                dense
                multiple
                @input="subjectInput = null"
              />
              <v-text-field
                v-model="model.data.title"
                label="Titel"
              />
              <v-menu
                v-model="menuRequiredOn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.data.requiredOn"
                    label="Behovsdato"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.data.requiredOn"
                  @input="menuRequiredOn = false"
                />
              </v-menu>
              <v-currency-field
                v-model="model.data.expectedDays"
                label="Forv. teknikerdage"
              />
              <v-textarea
                v-model="model.data.description"
                label="Beskrivelse"
              />
            </v-col>
          </v-row>
        </v-container>
        <div
          v-if="!!createResult"
          class="mt-4"
        >
          <div>
            {{ $t('taskRequests.createDialog.createResultSuccess') }}
          </div>
          <div>
            <router-link :to="`/taskrequest/${createResult.model.id}`">
              {{ createResult.model.code }}
            </router-link>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!createResult"
          outlined
          color="primary"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="!createResult"
          color="secondary"
          :loading="saving"
          :disabled="!isValid"
          @click="create"
        >
          {{ $t('taskRequests.createDialog.createTaskRequest') }}
        </v-btn>
        <v-btn
          v-if="!!createResult"
          outlined
          color="primary"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'TaskRequestCreate',
  components: {
    Loading,
  },
  props: {
    darkbtn: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      model: Object,
      createResult: null,
      //view only
      dialog: false,
      loaded: false,
      saving: false,
      isValid: false,
      menuRequiredOn: false,
      locationInput: null,
      departmentInput: null,
      subjectInput: null,
    };
  },
  watch: {
    model: {
      deep: true,
      handler(value) {
        if (value.data) {
          const { location, departments, subjectAreas, title, description } = value.data;

          this.isValid = location && departments?.length && subjectAreas?.length && title && description;
        }
      },
    },
  },
  methods: {
    init: async function () {
      try {
        const response = await this.$AuthService.api.get('TaskRequest/create');

        this.model = response.data;
        this.createResult = null;
      } catch (error) {
        console.error(error);
      } finally {
        this.loaded = true;
      }
    },
    create: async function () {
      this.saving = true;

      try {
        const response = await this.$AuthService.api.post(
          `/TaskRequest/Create`,
          this.model.data
        );

        this.createResult = response.data;
        this.$emit('saved');
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
      }
    },
    cancel: function () {
      this.dialog = false;
    },
    close: function () {
      //this.init();
      this.dialog = false;
    },
  },
};
</script>